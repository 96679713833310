// extracted by mini-css-extract-plugin
export var button = "style-module--button--268d8";
export var cardImage = "style-module--card-image--4a619";
export var cardImageImg = "style-module--card-image-img--57bcb";
export var cardRow = "style-module--card-row--5ea2b";
export var cardValuesHeader = "style-module--card-values-header--7128f";
export var cardValuesHeaderTablet = "style-module--card-values-header-tablet--fd6d7";
export var description = "style-module--description--e537b";
export var dropdown = "style-module--dropdown--b377f";
export var heading = "style-module--heading--23893";
export var innerProductComponent = "style-module--inner-product-component--b2ba3";
export var listHeader = "style-module--list-header--b90bb";
export var listHeaderMobile = "style-module--list-header-mobile--d8b28";
export var productComponent = "style-module--product-component--c74a9";
export var rightContainer = "style-module--right-container--acbed";