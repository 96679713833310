import React, { useEffect, useState } from "react"
import { CircularProgress, useMediaQuery } from "@mui/material"
import Product from "models/Product"
import Provider from "models/Provider"
import HomeLoanProduct from "models/product-models/HomeLoanProduct"
import HomeLoanProvider from "models/provider-models/HomeLoanProvider"
import ProductComponent from "components/content/home-loan/product-component"
import { ListHeaderProvider } from "components/content/list-header"
import * as styles from "./style.module.scss"
import Layout from "components/layout"
import ReturnLinkChain from "components/content/return-link-chain"
import { Typography } from "components/typography"
import Sort from "components/content/sort"
import FilterMenu from "components/content/filter-menu"
import { makeSchemaOrgProvider } from "components/schema"
import { LoanValuesHeader } from "components/content/product-values-header"
import InputFilter from "components/content/home-loan/input-filter"
import HomeLoanFeature from "models/feature-models/HomeLoanFeature"
import { HomeLoanDisclaimer } from "components/content/content-disclaimer"
import AppManager from "services/app-manager"
import HomeLoanOffer from "models/offer-models/HomeLoanOffer"
import Pagination from "components/pagination"
import { compileSchemaOrgQa } from "components/schema"
import Markdown from "components/markdown"
import Faq from "components/content/faq"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const ProviderTemplate = (props: any) => {
  const { categoryJson, dateUpdated, ads, qa }: { categoryJson: Provider; dateUpdated: string; ads: any; qa?: any } = JSON.parse(props.pageContext.p)
  const isTablet = !useMediaQuery("(min-width:1024px)")
  const isWide = useMediaQuery("(min-width:768px)")
  const [filter, setFilter] = useState<string[]>(["For Sale"])
  const [sort, setOnlySort] = useState("Interest Rate")
  const [tempFilter, setTempFilter] = useState<string[]>([])
  const [linkFirst, setLinkFirst] = useState(true)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)
  const [isLoading, setLoading] = useState(false)
  // const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [offersPerPage, setOffersPerPage] = useState(10)

  const appManager = AppManager.getInstance()
  const [loanAmount, setHomeLoanAmount] = useState(appManager.getHomeLoanAmount())
  const [loanDeposit, setLoanDeposit] = useState(appManager.getLoanDeposit())
  const [loanPurpose, setLoanPurpose] = useState(appManager.getLoanPurpose())

  const updateFilter = (v: string[]) => {
    setPage(0)
    setFilter(v)
  }

  const updateOnlySort = (v: string) => {
    setPage(0)
    setOnlySort(v)
  }

  const updateTempFilter = (v: string[]) => {
    setPage(0)
    setTempFilter(v)
  }

  const updateLoanAmount = (v: number) => {
    setPage(0)
    setHomeLoanAmount(v)
  }

  const updateLoanDeposit = (v: number) => {
    setPage(0)
    setLoanDeposit(v)
  }

  const updateLoanPurpose = (v: string) => {
    setPage(0)
    setLoanPurpose(v)
  }

  function makeProductComponent(product: Product, key: number, rateId: string, isLast?: boolean, offerFilter?: string) {
    return (
      <div key={product.id.toString() + "-" + key.toString() + "-" + rateId.toString()} style={{ borderBottom: isLast && !isLast ? "" : "0.5pt #D2D2D2 solid" }} className={styles.productComponent}>
        <div className={styles.innerProductComponent}>
          <ProductComponent product={product as HomeLoanProduct} rateId={rateId} loanAmount={loanAmount} loanDeposit={loanDeposit} learnMore offerFilter={offerFilter} isTablet={isTablet} />
        </div>
      </div>
    )
  }

  function isProductComponent(component: JSX.Element) {
    return component.props.className && component.props.className === styles.productComponent
  }

  function makeCategoryHeader(headerName: string) {
    return (
      // <div key={headerName} className={!isWide || isTablet ? style.listHeaderMobile : style.listHeader}>
      //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{headerName}</div>
      // </div>
      <ListHeaderProvider key={headerName} isMobile={!isWide || isTablet}>
        {headerName}
      </ListHeaderProvider>
    )
  }

  function setSort(name: string) {
    setLoading(true)
    updateOnlySort(name)
  }

  function checkOffer(offer: HomeLoanOffer) {
    if (!((offer.minAmount === null || offer.minAmount <= loanAmount) && (offer.maxAmount === null || offer.maxAmount >= loanAmount) && (offer.maxLvr === null || offer.maxLvr >= 100 - loanDeposit))) {
      return false
    }
    return true
  }

  function compare(a: JSX.Element, b: JSX.Element, sort: string): number {
    if (isProductComponent(a) && isProductComponent(b)) {
      const productA: HomeLoanProduct = a.props.children.props.children.props.product
      const rateIdA = a.props.children.props.children.props.rateId
      const productB: HomeLoanProduct = b.props.children.props.children.props.product
      const rateIdB = b.props.children.props.children.props.rateId
      switch (sort) {
        case "Interest Rate":
          return productA.rates[rateIdA].interestRate - productB.rates[rateIdB].interestRate
        case "Comparison Rate":
          return productA.rates[rateIdA].comparisonRate - productB.rates[rateIdB].comparisonRate
        case "Cash Back Offer":
          let highestCashBackA = 0
          let highestCashBackB = 0

          productA.rates[rateIdA].offers.forEach((offerId: string) => {
            const offer = productA.offers[offerId] as HomeLoanOffer
            if ((offer.minAmount === null || offer.minAmount <= loanAmount) && (offer.maxAmount === null || offer.maxAmount >= loanAmount) && (offer.maxLvr === null || offer.maxLvr >= 100 - loanDeposit) && offer.cashBack !== null && offer.cashBack > highestCashBackA) {
              highestCashBackA = offer.cashBack
            }
          })

          productB.rates[rateIdB].offers.forEach((offerId: string) => {
            const offer = productB.offers[offerId] as HomeLoanOffer
            if ((offer.minAmount === null || offer.minAmount <= loanAmount) && (offer.maxAmount === null || offer.maxAmount >= loanAmount) && (offer.maxLvr === null || offer.maxLvr >= 100 - loanDeposit) && offer.cashBack !== null && offer.cashBack > highestCashBackB) {
              highestCashBackB = offer.cashBack
            }
          })
          let rank = highestCashBackB - highestCashBackA

          if (rank === 0) {
            rank = productA.rates[rateIdA].interestRate - productB.rates[rateIdB].interestRate
          }
          return rank
        case "Link First":
          const hasOfferLinkA = productA.rates[rateIdA].offers.some(oid => productA.offers[oid].active === "Y" && productA.offers[oid].landingPageUrl !== null && checkOffer(productA.offers[oid] as HomeLoanOffer)) || !!productA.finspoPanel
          const hasOfferLinkB = productB.rates[rateIdB].offers.some(oid => productB.offers[oid].active === "Y" && productB.offers[oid].landingPageUrl !== null && checkOffer(productB.offers[oid] as HomeLoanOffer)) || !!productB.finspoPanel
          if (hasOfferLinkA && !hasOfferLinkB) {
            return -1
          } else if (hasOfferLinkB && !hasOfferLinkA) {
            return 1
          } else if (productA.rates[rateIdA].interestRate - productB.rates[rateIdB].interestRate == 0) {
            return productA.rates[rateIdA].comparisonRate - productB.rates[rateIdB].comparisonRate
          } else {
            return productA.rates[rateIdA].interestRate - productB.rates[rateIdB].interestRate
          }
      }
    }
    return 0
  }

  let category: any = null
  if ("big4" in categoryJson) {
    category = HomeLoanProvider.Initialise(categoryJson)
  } else {
    category = HomeLoanFeature.Initialise(categoryJson)
  }

  let productList: JSX.Element[] = category.getProductList(makeProductComponent, makeCategoryHeader)

  // Filter out products
  const productGroups: JSX.Element[][] = []
  productList.forEach(productComponent => {
    if (productComponent) {
      if (isProductComponent(productComponent)) {
        const propsProduct: HomeLoanProduct = productComponent.props.children.props.children.props.product
        const rateId: number = productComponent.props.children.props.children.props.rateId

        // Filter out products by feature
        if (category.name === "Fixed Rate") {
          if (propsProduct.rates[rateId].rateType !== "FIXED") {
            return
          }
        } else if (category.name === "Variable Rate") {
          if (propsProduct.rates[rateId].rateType !== "VARIABLE") {
            return
          }
        } else if (category.name === "Cashback") {
          const hasCashBackOffer = propsProduct.rates[rateId].offers.some(offerId => {
            const offer = propsProduct.offers[offerId] as HomeLoanOffer
            if ((offer.minAmount === null || offer.minAmount <= loanAmount) && (offer.maxAmount === null || offer.maxAmount >= loanAmount) && (offer.maxLvr === null || offer.maxLvr >= 100 - loanDeposit) && offer.cashBack && offer.cashBack > 0) {
              return true
            }
          })
          if (!hasCashBackOffer) {
            return
          }
        }

        // Filter out products by user input filter
        if (Object.keys(propsProduct.rates).length > 0) {
          const minAmount = propsProduct.rates[rateId].minAmount ? (propsProduct.rates[rateId].minAmount as number) : propsProduct.minAmount
          const maxAmount = propsProduct.rates[rateId].maxAmount ? (propsProduct.rates[rateId].maxAmount as number) : propsProduct.maxAmount
          const maxLvr = propsProduct.rates[rateId].maxLvr ? (propsProduct.rates[rateId].maxLvr as number) : propsProduct.maxLvr

          if (!(minAmount <= loanAmount && maxAmount >= loanAmount && maxLvr >= 100 - loanDeposit && ((propsProduct.rates[rateId].loanPurpose === "OWNER_OCCUPIED" && loanPurpose === "Home") || (propsProduct.rates[rateId].loanPurpose === "INVESTMENT" && loanPurpose === "Investment")))) {
            return
          }

          const minRateMap = {
            PRINCIPAL_AND_INTEREST: {},
            INTEREST_ONLY: {},
          }
          Object.values(propsProduct.rates).forEach(rate => {
            if (!(rate.minAmount <= loanAmount && rate.maxAmount >= loanAmount && rate.maxLvr >= 100 - loanDeposit && ((rate.loanPurpose === "OWNER_OCCUPIED" && loanPurpose === "Home") || (rate.loanPurpose === "INVESTMENT" && loanPurpose === "Investment")))) {
              return
            }

            const minRateMapKey = rate.fixedTerm ? rate.fixedTerm : "variable"

            if (!(minRateMapKey in minRateMap[rate.repaymentType])) {
              minRateMap[rate.repaymentType][minRateMapKey] = rate.interestRate
            }

            if (rate.interestRate < minRateMap[rate.repaymentType][minRateMapKey]) {
              minRateMap[rate.repaymentType][minRateMapKey] = rate.interestRate
            }
          })

          const minRateMapKey = propsProduct.rates[rateId].fixedTerm ? propsProduct.rates[rateId].fixedTerm : "variable"
          if (propsProduct.rates[rateId].interestRate > minRateMap[propsProduct.rates[rateId].repaymentType][minRateMapKey]) {
            return
          }
        }

        // Filter out products by filter check box
        let rateTypeFilter: boolean[] = []
        let repaymentTypeFilter: boolean[] = []
        let providerFilter: boolean[] = []
        let availabilityFilter: boolean[] = []
        let offerFilter: boolean[] = []
        filter.forEach(filterType => {
          const fixedTerm = propsProduct.rates[rateId].fixedTerm != null ? Number(propsProduct.rates[rateId].fixedTerm?.replace(/\D+/g, "")) : 0
          switch (filterType) {
            case "1, 2 Year Fixed":
              if (fixedTerm === 1 || fixedTerm === 2) {
                rateTypeFilter.push(true)
              } else {
                rateTypeFilter.push(false)
              }
              break
            case "3, 4 Year Fixed":
              if (fixedTerm === 3 || fixedTerm === 4) {
                rateTypeFilter.push(true)
              } else {
                rateTypeFilter.push(false)
              }
              break
            case "5+ Year Fixed":
              if (fixedTerm >= 5) {
                rateTypeFilter.push(true)
              } else {
                rateTypeFilter.push(false)
              }
              break
            case "Variable":
              if (propsProduct.rates[rateId].rateType === "VARIABLE") {
                rateTypeFilter.push(true)
              } else {
                rateTypeFilter.push(false)
              }
              break
            case "Principal & Interest":
              if (propsProduct.rates[rateId].repaymentType === "PRINCIPAL_AND_INTEREST") {
                repaymentTypeFilter.push(true)
              } else {
                repaymentTypeFilter.push(false)
              }
              break
            case "Interest Only":
              if (propsProduct.rates[rateId].repaymentType === "INTEREST_ONLY") {
                repaymentTypeFilter.push(true)
              } else {
                repaymentTypeFilter.push(false)
              }
              break
            case "Major Banks":
              if (propsProduct.providerId === "00100" || propsProduct.providerId === "00200" || propsProduct.providerId === "00300" || propsProduct.providerId === "00400") {
                providerFilter.push(true)
              } else {
                providerFilter.push(false)
              }
              break
            case "Challengers":
              if (!(propsProduct.providerId === "00100" || propsProduct.providerId === "00200" || propsProduct.providerId === "00300" || propsProduct.providerId === "00400")) {
                providerFilter.push(true)
              } else {
                providerFilter.push(false)
              }
              break
            case "Non-banks":
              providerFilter.push(false)
              break
            case "For Sale":
              if (propsProduct.forSale !== "Y" && propsProduct.forSale !== "EXCLUDED") {
                availabilityFilter.push(false)
              } else {
                availabilityFilter.push(true)
              }
              break
            case "Not For Sale":
              if (propsProduct.forSale === "Y" || propsProduct.forSale === "EXCLUDED") {
                availabilityFilter.push(false)
              } else {
                availabilityFilter.push(true)
              }
              break
            case "Cash Back":
              if (propsProduct.rates[rateId].offers.some(offerId => propsProduct.offers[offerId].cashBack > 0)) {
                offerFilter.push(true)
              } else {
                offerFilter.push(false)
              }
          }
        })
        if ((rateTypeFilter.includes(true) || rateTypeFilter.length === 0) && (repaymentTypeFilter.includes(true) || repaymentTypeFilter.length === 0) && (providerFilter.includes(true) || providerFilter.length === 0) && (availabilityFilter.includes(true) || availabilityFilter.length === 0) && (offerFilter.includes(true) || offerFilter.length === 0)) {
          productGroups[productGroups.length - 1].push(productComponent)
        }
      } else {
        if (sort === "Provider") {
          productGroups.push([productComponent])
        } else {
          productGroups.push([])
        }
      }
    }
  })

  productList = []
  productGroups.forEach(group => {
    if (group.length > 1 || (sort !== "Provider" && group.length > 0)) {
      productList = productList.concat(group)
    }
  })

  // Add "No Products" component if empty
  if (productList.length === 0) {
    productList.push(<div style={{ textAlign: "center", fontSize: "24px", marginTop: "30px", fontFamily: "Arboria-Book" }}>No Products</div>)
  } else {
    // Sort products
    switch (sort) {
      case "Interest Rate":
        productList.sort((a, b) => compare(a, b, sort))
        break
      case "Comparison Rate":
        productList.sort((a, b) => compare(a, b, sort))
        break
      case "Cash Back Offer":
        productList.sort((a, b) => compare(a, b, sort))
    }
    if (linkFirst) {
      productList.sort((a, b) => compare(a, b, "Link First"))
    }
  }

  // sortList
  const sortList: string[] = ["Interest Rate", "Comparison Rate", "Cash Back Offer"]

  // filterList
  const filterList: { [type: string]: string[] } = {
    "repayment type": ["Principal & Interest", "Interest Only"],
    availability: ["For Sale", "Not For Sale"],
    offer: ["Cash Back"],
  }

  if (category.name === "Fixed Rate") {
    filterList["rate type"] = ["1, 2 Year Fixed", "3, 4 Year Fixed", "5+ Year Fixed"]
  } else if (category.name === "Variable Rate") {
  } else {
    filterList["rate type"] = ["1, 2 Year Fixed", "3, 4 Year Fixed", "5+ Year Fixed", "Variable"]
  }

  if (!("big4" in categoryJson)) {
    filterList["provider"] = ["Major Banks", "Challengers", "Non-banks"]
  }

  // filterAmounts
  const filterAmounts: { [id: string]: number } = {
    "1, 2 Year Fixed": 0,
    "3, 4 Year Fixed": 0,
    "5+ Year Fixed": 0,
    Variable: 0,
    "Principal & Interest": 0,
    "Interest Only": 0,
    "Major Banks": 0,
    Challengers: 0,
    "Non-banks": 0,
    "For Sale": 0,
    "Not For Sale": 0,
    "Cash Back": 0,
  }

  // filterAmounts
  let totalAmount: number = 0
  productList.forEach((productComponent: JSX.Element) => {
    if (productComponent.props.children !== "No Products") {
      // if (typeof productComponent.props.children.props.children !== "string") {
      if (isProductComponent(productComponent)) {
        // less hacky
        totalAmount += 1
        const product: HomeLoanProduct = productComponent.props.children.props.children.props.product
        const rateId = productComponent.props.children.props.children.props.rateId
        const fixedTerm = product.rates[rateId].fixedTerm != null ? Number(product.rates[rateId].fixedTerm?.replace(/\D+/g, "")) : 0
        // filterAmounts
        if (fixedTerm === 1 || fixedTerm === 2) {
          filterAmounts["1, 2 Year Fixed"] += 1
        } else if (fixedTerm === 3 || fixedTerm === 4) {
          filterAmounts["3, 4 Year Fixed"] += 1
        } else if (fixedTerm >= 5) {
          filterAmounts["5+ Year Fixed"] += 1
        } else if (product.rates[rateId].rateType === "VARIABLE") {
          filterAmounts["Variable"] += 1
        }

        if (product.rates[rateId].repaymentType === "PRINCIPAL_AND_INTEREST") {
          filterAmounts["Principal & Interest"] += 1
        } else if (product.rates[rateId].repaymentType === "INTEREST_ONLY") {
          filterAmounts["Interest Only"] += 1
        }

        if (product.providerId === "00100" || product.providerId === "00200" || product.providerId === "00300" || product.providerId === "00400") {
          filterAmounts["Major Banks"] += 1
        } else if (!(product.providerId === "00100" || product.providerId === "00200" || product.providerId === "00300" || product.providerId === "00400")) {
          filterAmounts["Challengers"] += 1
        } else {
          filterAmounts["Non-banks"] += 1
        }

        if (product.forSale === "Y" || product.forSale === "EXCLUDED") {
          filterAmounts["For Sale"] += 1
        } else {
          filterAmounts["Not For Sale"] += 1
        }

        const hasCashBackOffer = product.rates[rateId].offers.some(offerId => {
          const offer = product.offers[offerId] as HomeLoanOffer
          if ((offer.minAmount === null || offer.minAmount <= loanAmount) && (offer.maxAmount === null || offer.maxAmount >= loanAmount) && (offer.maxLvr === null || offer.maxLvr >= 100 - loanDeposit) && offer.cashBack > 0) {
            return true
          }
        })
        if (hasCashBackOffer) {
          filterAmounts["Cash Back"] += 1
        }
      }
    }
  })

  // Pagination
  const paginatedProductLists: JSX.Element[][] = []

  while (productList.length) {
    paginatedProductLists.push(productList.splice(0, offersPerPage))
  }

  productList = paginatedProductLists[page]

  const currentOfferShown = (offersPerPage * page + 1).toString() + " - " + (offersPerPage * page + paginatedProductLists[page].length).toString()

  const paginationRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (productList.length > 0) {
      setLoading(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (category.name === "Cashback") {
      setSort("Cash Back Offer")
    }
  }, [])

  const title = category.name === "Cashback" ? `Home Loan Cashback Offers - Up to $${category.getMaxCashbackAmount().toLocaleString("en")} back` : `${category.name} Home Loans Comparison & Reviews | Stay or Go`

  return (
    <Layout title={title} description={category.getAutoDescription()} urlSlug={`${category.urlSlug ? category.urlSlug : category.nameFormatted}-home-loans`} dateUpdated={dateUpdated}>
      {"big4" in categoryJson ? makeSchemaOrgProvider(categoryJson, "hl") : null}
      <div className="page-container" style={{ display: "flex", alignItems: "stretch" }}>
        <div className="content-provider-container" style={{ maxWidth: isTablet ? (isWide ? "648px" : "395px") : "960px" }}>
          <ReturnLinkChain linkChain={[{ name: "Home Loans", to: "/home-loans/" }]} currentLocationItem={{ name: category.heading }} excludeBottomMargin={isWide} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={dateString} />
          <div className={styles.heading}>{category.heading === "Best Rate" ? <Typography.H1>Compare Best Home Loan Rates</Typography.H1> : category.heading === "Cashback" ? <Typography.H1>Compare Home Loan Cashback Offers</Typography.H1> : <Typography.H1>Compare {category.heading} Home Loans</Typography.H1>}</div>
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {category.getAutoDescription()}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          {/* {ads ? (
            <Ads ads={ads} />
          ) : null} */}
          <div ref={paginationRef} />
          <InputFilter setHomeLoanAmount={updateLoanAmount} setLoanDeposit={updateLoanDeposit} setLoanPurpose={updateLoanPurpose} />
          <div style={{ marginTop: "40px" }} />
          <div className={styles.cardValuesHeader}>
            <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} mobile={true} headerType={"home-loan"} linkFirst={linkFirst} setLinkFirst={setLinkFirst} productValuesHeaderComponent={<LoanValuesHeader setSort={setSort} sort={sort} />} />
          </div>
          <div style={{ display: "flex", opacity: isLoading ? "0.4" : "1" }}>
            <div style={{ maxWidth: "648px", width: "100%" }}>
              {sort !== "Provider" && (!isTablet || !isWide) ? <ListHeaderProvider isMobile={!isWide || isTablet}>{sort}</ListHeaderProvider> : null}
              {isTablet && isWide ? (
                <div style={{ display: "flex" }}>
                  <div style={{ width: "337.5px", marginRight: "50px" }}>
                    <div className={styles.cardValuesHeaderTablet}>
                      <div style={{ marginLeft: "19px", marginRight: "19px", display: "flex" }}>
                        <LoanValuesHeader setSort={setSort} sort={sort} />
                      </div>
                    </div>
                    {sort !== "Provider" ? <ListHeaderProvider isMobile>{sort}</ListHeaderProvider> : null}
                    {productList}
                    <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} scrollRef={paginationRef} />
                    {/* {"big4" in categoryJson && productListPage2.length > 0 ? productListPage1 : productList}
                    {"big4" in categoryJson && productListPage2.length > 0 ? (
                      <div>
                        <div
                          style={{ cursor: "pointer", color: "#202020", width: "100%", height: "40px", display: "flex", justifyContent: "space-between", marginTop: "50px", alignItems: "center" }}
                          onClick={() => {
                            setOpen(!open)
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div style={{ textAlign: "left", height: "18px", display: "block", lineHeight: "18px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>See more offers</div>
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </div>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          {productListPage2}
                        </Collapse>
                      </div>
                    ) : null} */}
                  </div>
                  <div style={{ marginTop: "70px" }}>
                    <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>{totalAmount === 0 ? "No Offers" : currentOfferShown + " of " + totalAmount}</div>
                    <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} headerType={"home-loan"} linkFirst={linkFirst} setLinkFirst={setLinkFirst} />
                  </div>
                </div>
              ) : (
                <div>
                  {productList}
                  <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} scrollRef={paginationRef} />
                  {/* {"big4" in categoryJson && productListPage2.length > 0 ? productListPage1 : productList}
                  {"big4" in categoryJson && productListPage2.length > 0 ? (
                    <div>
                      <div
                        style={{ cursor: "pointer", color: "#202020", width: "100%", height: "40px", display: "flex", justifyContent: "space-between", marginTop: "50px", alignItems: "center" }}
                        onClick={() => {
                          setOpen(!open)
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div style={{ textAlign: "left", height: "18px", display: "block", lineHeight: "18px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>See more offers</div>
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </div>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        {productListPage2}
                      </Collapse>
                    </div>
                  ) : null} */}
                </div>
              )}
            </div>
            {!isTablet ? (
              <div style={{ marginTop: "10px", marginLeft: "43px" }}>
                <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>{totalAmount === 0 ? "No Offers" : currentOfferShown + " of " + totalAmount}</div>
                <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} headerType={"home-loan"} linkFirst={linkFirst} setLinkFirst={setLinkFirst} />
              </div>
            ) : null}
          </div>
          <div style={{ maxWidth: "648px" }}>
            {qa ? (
              <div style={{ marginTop: "60px" }}>
                {/* {compileSchemaOrgQa(qa.qa.qa)} */}
                {/* <div className={styles.listHeader}>
                    <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{"FAQs"}</div>
                  </div> */}
                <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
                {/* <div>
                  <Markdown md={qa.qa.qa} isArticle={true} />
                </div> */}
                <Faq faqContent={qa.qa.qa} />
              </div>
            ) : null}
            <HomeLoanDisclaimer isDisclaimerOpen isProvider />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProviderTemplate
