import HomeLoanProduct from "../product-models/HomeLoanProduct"
import Product from "../Product"
import Feature from "../Feature"
import HomeLoanOffer from "models/offer-models/HomeLoanOffer"

let obj: any = null
export default class HomeLoanFeature extends Feature {
  // = CUSTOM METHODS
  public getMaxCashbackAmount(): number {
    let maxCashBack = 0

    Object.keys(this.products).forEach(productId => {
      const product = this.products[productId]
      Object.keys(product.offers).forEach(offerId => {
        const offer = product.offers[offerId] as HomeLoanOffer
        // Check for Cash Back
        if (offer.cashBack !== null) {
          if (offer.cashBack > maxCashBack) {
            maxCashBack = offer.cashBack
          }
        }
      })
    })

    return maxCashBack
  }

  // = ABSTRACT OVERRIDES

  // TODO: remove this?
  public getFilterAmounts(): { [id: string]: number } {
    // filterAmounts
    const filterAmounts: { [id: string]: number } = {
      "1, 2 Year Fixed": 0,
      "3, 4 Year Fixed": 0,
      "5+ Year Fixed": 0,
      Variable: 0,
      "Principal & Interest": 0,
      "Interest Only": 0,
      "Major Banks": 0,
      Challengers: 0,
      "Non-banks": 0,
      "For Sale": 0,
      "Not For Sale": 0,
    }

    Object.keys(this.products).forEach((productId: string) => {
      const product = this.products[productId] as HomeLoanProduct
      const productFilterAmounts = product.getFilterAmounts()
      Object.keys(productFilterAmounts).forEach(id => {
        if (filterAmounts[id] !== undefined) {
          filterAmounts[id] += productFilterAmounts[id]
        } else {
          filterAmounts[id] = productFilterAmounts[id]
        }
      })
    })

    return filterAmounts
  }

  public getTitle(): string {
    return ""
  }

  public getAutoDescription(): string {
    let maxCashBack = [0, ""]
    let minRate = Number.MAX_VALUE
    let minComparisonRate = Number.MAX_VALUE
    let maxLvr = Number.MIN_VALUE

    Object.keys(this.products).forEach(productId => {
      const product = this.products[productId] as HomeLoanProduct
      Object.keys(product.offers).forEach(offerId => {
        const offer = product.offers[offerId] as HomeLoanOffer
        // Check for Cash Back
        if (offer.cashBack !== null && offer.cbDescription !== null) {
          if (offer.cashBack > (maxCashBack[0] as number)) {
            maxCashBack = [offer.cashBack, offer.cbDescription]
          }
        }
      })

      Object.keys(product.rates).forEach(rateId => {
        const rate = product.rates[rateId]

        if (rate.interestRate < minRate) {
          minRate = rate.interestRate
        }

        if (rate.comparisonRate && rate.comparisonRate < minComparisonRate) {
          minComparisonRate = rate.comparisonRate
        }

        if (rate.maxLvr && rate.maxLvr > maxLvr) {
          maxLvr = rate.maxLvr
        }
      })
    })

    let autoDescription = "Compare the latest " + this.name + " Home Loan offers."
    if (this.name === "Best Rate") {
      autoDescription = "Compare the best home loan rates from leading banks, including ANZ, Commbank, NAB and Westpac."
    }

    if ((maxCashBack[0] as number) > 0) {
      autoDescription += " Up to $" + maxCashBack[0].toLocaleString() + " when you refinance."
    }

    if (minRate < Number.MAX_VALUE) {
      autoDescription += " Rates from " + (minRate * 100).toFixed(2).toLocaleString() + "% p.a."
      if (minComparisonRate < Number.MAX_VALUE) {
        autoDescription += " (" + (minComparisonRate * 100).toFixed(2).toLocaleString() + "% p.a. comparison rate*)."
      }
    }

    if (maxLvr >= 90 && this.name !== "Best Rate") {
      // autoDescription += " " + (100-maxLvr).toLocaleString() + "% deposit home loans."
      autoDescription += " Low deposit home loans."
    }

    return autoDescription
  }

  // getProductList
  public getProductList(makeProductComponent: (product: Product, key: number, rateId: string, isLast?: boolean | undefined, offerFilter?: string) => JSX.Element, makeCategoryHeader: (headerName: string) => JSX.Element): JSX.Element[] {
    const productList: JSX.Element[] = []

    const sortedProducts: Product[] = []
    Object.values(this.products).forEach(p => {
      sortedProducts.push(p)
    })
    sortedProducts.sort((a, b) => this.compare(a, b))

    // Categorise by providers
    const productsByProviders: { [id: string]: Product[] } = {}
    sortedProducts.forEach(product => {
      const productProvider: string = product.providerName

      if (productProvider in productsByProviders) {
        productsByProviders[productProvider].push(product)
      } else {
        productsByProviders[productProvider] = [product]
      }
    })

    // Fill product list
    Object.keys(productsByProviders).forEach(providerName => {
      const products: Product[] = productsByProviders[providerName]

      // Add Provider Header to list
      if (products.length > 0) {
        productList.push(makeCategoryHeader(providerName))
      }

      // Add Product Components to list
      products.forEach((prod: any, key: number) => {
        const hlProd = prod as HomeLoanProduct
        Object.keys(hlProd.rates).forEach((rateId, i) => {
          if (hlProd.rates[rateId].rateCategory !== "OFFER") {
            return
          }
          productList.push(makeProductComponent(prod, key, rateId, i === Object.keys(hlProd.rates).length - 1))
        })
      })
    })

    return productList
  }

  // = AUTOMATED FUNCTIONS =
  public static Parse(d: string): HomeLoanFeature {
    return HomeLoanFeature.Create(JSON.parse(d))
  }
  public static Create(d: any, field: string = "root"): HomeLoanFeature {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.name, false, field + ".name")
    checkString(d.urlSlug, true, field + ".urlSlug")
    d.nameFormatted = d.name
    d.heading = d.name
    d.products = {}
    return new HomeLoanFeature(d)
  }
  public static Initialise(d: any): HomeLoanFeature {
    return new HomeLoanFeature(d)
  }
  private constructor(d: any) {
    super(d)
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}
