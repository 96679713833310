import Provider from "../Provider"
import Product from "../Product"
import Reward from "../Reward"
import HomeLoanProduct from "../product-models/HomeLoanProduct"
import HomeLoanOffer from "../offer-models/HomeLoanOffer"

type hlPackage = {
  packageId: string
  name: string
  nameLong: string
  urlSlug: string
  packageFee: number
  pfFreq: string
  description: string | null
}

let obj: any = null
export default class HomeLoanProvider extends Provider {
  public readonly finspoPanel: number | null
  public readonly hlPackages: hlPackage[]

  // = CUSTOM METHODS =
  public getMaxCashbackAmount(): number {
    let maxCashBack = 0

    Object.keys(this.products).forEach(productId => {
      const product = this.products[productId]
      Object.keys(product.offers).forEach(offerId => {
        const offer = product.offers[offerId] as HomeLoanOffer
        // Check for Cash Back
        if (offer.cashBack !== null) {
          if (offer.cashBack > maxCashBack) {
            maxCashBack = offer.cashBack
          }
        }
      })
    })

    return maxCashBack
  }

  // = ABSTRACT OVERRIDES =
  public getAutoDescription(): string {
    return this.autoDescription
  }

  // Alternative Products
  public getAlternateProductsByProduct(product: Product): Product[] {
    const alternativeProducts: Product[] = []
    // TODO: implement this
    return alternativeProducts
  }

  public getUnfilteredProductsByProduct(product: Product): Product[] {
    const alternativeProducts: Product[] = []
    if (!(product instanceof HomeLoanProduct)) {
      return alternativeProducts
    }
    Object.keys(this.products).forEach(productId => {
      if (productId.toString() !== product.id.toString()) {
        // TODO: define how to show similar products
      }
    })
    return alternativeProducts
  }

  public getAlternativeProductList(product: Product, makeProductComponent: (product: Product, key: number, isLast?: boolean | undefined) => JSX.Element): JSX.Element[] {
    // Alternative Products
    const alternativeProductList: JSX.Element[] = this.getAlternateProductsByProduct(product).map((singleProduct, productKey) => makeProductComponent(singleProduct, productKey, productKey === this.getAlternateProductsByProduct(product).length - 1))
    return alternativeProductList
  }

  // getProductList
  public getProductList(makeProductComponent: (product: Product, key: number, rateId: string, isLast?: boolean | undefined) => JSX.Element, makeCategoryHeader: (headerName: string) => JSX.Element): JSX.Element[] {
    const productList: JSX.Element[] = []
    const products = Object.values(this.products)
    if (products.length > 0) {
      productList.push(makeCategoryHeader(this.name + " Home Loans"))
    }

    products.forEach((prod, key) => {
      const hlProd = prod as HomeLoanProduct
      Object.keys(hlProd.rates).forEach((rateId, i) => {
        if (hlProd.rates[rateId].rateCategory !== "OFFER") {
          return
        }
        productList.push(makeProductComponent(prod, key, rateId, i === Object.keys(hlProd.rates).length - 1))
      })
    })
    return productList
  }

  // = AUTOMATED FUNCTIONS =
  public static Create(d: any, rewards: { [id: string]: Reward }, field: string = "root"): Provider {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.id, false, field + ".id")
    d.id = d.id
    checkString(d.name, false, field + ".name")
    d.name = d.name
    checkNumber(d.finspo_panel, true, ".finspo_panel")
    if (d.finspo_panel === undefined) {
      d.finspo_panel = 0
    }
    d.finspoPanel = d.finspo_panel
    d.nameFormatted = d.name.toLowerCase().replace(/ /g, "-")
    checkBoolean(d.big4, false, field + ".big4")
    // Create Product Dictionary
    const productsDict: { [id: string]: Product } = {}
    Object.keys(d.products).forEach(productId => {
      productsDict[productId] = HomeLoanProduct.Create(d.products[productId], rewards, d.name, d.finspoPanel, d.participant, field + ".products." + productId)
    })
    d.products = productsDict

    // Craete Package Dictionary
    for (let i = 0; i < d.packages.length; i++) {
      const pack = d.packages[i]
      if (Object.keys(pack).length > 0) {
        checkString(pack.package_id, false, field + ".package[" + pack.package_id + "][package_id]")
        checkString(pack.name, false, field + ".package[" + pack.name + "][name]")
        checkString(pack.name_long, false, field + ".package[" + pack.name_long + "][name_long]")
        checkString(pack.url_slug, false, field + ".package[" + pack.url_slug + "][url_slug]")
        checkNumber(pack.package_fee, false, field + ".package[" + pack.package_fee + "][package_fee]")
        checkString(pack.pf_freq, false, field + ".package[" + pack.pf_freq + "][pf_freq]")
        checkString(pack.description, true, field + ".package[" + pack.description + "][description]")
        if (pack.description === undefined) {
          pack.description = null
        }
        d.packages[i] = {
          packageId: pack.package_id,
          name: pack.name,
          nameLong: pack.name_long,
          urlSlug: pack.url_slug,
          packageFee: pack.package_fee,
          pfFreq: pack.pf_freq,
          description: pack.description,
        }
      }
    }

    // Initialise description values
    let maxCashBack = [0, ""]
    let minRate = Number.MAX_VALUE
    let minComparisonRate = Number.MAX_VALUE
    let maxLvr = Number.MIN_VALUE

    Object.keys(d.products).forEach(productId => {
      const product = d.products[productId]
      Object.keys(product.offers).forEach(offerId => {
        const offer = product.offers[offerId]
        // Check for Cash Back
        if (offer.cashBack !== null) {
          if (offer.cashBack > maxCashBack[0]) {
            maxCashBack = [offer.cashBack, offer.cbDescription]
          }
        }
      })

      Object.keys(product.rates).forEach(rateId => {
        const rate = product.rates[rateId]

        if (rate.interestRate < minRate) {
          minRate = rate.interestRate
        }

        if (rate.comparisonRate && rate.comparisonRate < minComparisonRate) {
          minComparisonRate = rate.comparisonRate
        }

        if (rate.maxLvr > maxLvr) {
          maxLvr = rate.maxLvr
        }
      })
    })

    d.autoDescription = "Compare the latest " + d.name + " Home Loan offers."

    if ((maxCashBack[0] as number) > 0) {
      d.autoDescription += " Up to $" + maxCashBack[0].toLocaleString() + " " + maxCashBack[1] + "."
    }

    if (minRate < Number.MAX_VALUE) {
      d.autoDescription += " Rates from " + (minRate * 100).toFixed(2).toLocaleString() + "% p.a."
      if (minComparisonRate < Number.MAX_VALUE) {
        d.autoDescription += " (" + (minComparisonRate * 100).toFixed(2).toLocaleString() + "% p.a. comparison rate*)."
      }
    }

    if (maxLvr >= 90) {
      // d.autoDescription += " " + (100-maxLvr).toLocaleString() + "% deposit home loans."
      d.autoDescription += " Low deposit home loans."
    }

    // // filterAmounts
    // d.filterAmounts = {
    //   "1, 2 Year Fixed": 0,
    //   "3, 4 Year Fixed": 0,
    //   "5+ Year Fixed": 0,
    //   "Variable": 0,
    //   "Principal & Interest": 0,
    //   "Interest Only": 0,
    //   "Major Banks": 0,
    //   "Challengers": 0,
    //   "Non-banks": 0,
    // }

    // d.productsByRewards = {}  // leave empty for now, define if Rewards are added for HLs

    // Object.keys(d.products).forEach((productId: string) => {
    //   const product: Product = d.products[productId] as HomeLoanProduct
    //   if (!(product instanceof HomeLoanProduct)) {
    //     return
    //   }

    //   // filterAmounts
    //   const productFilterAmounts = product.getFilterAmounts()
    //   Object.keys(productFilterAmounts).forEach(id => {
    //     if (d.filterAmounts[id] !== undefined) {
    //       d.filterAmounts[id] += productFilterAmounts[id]
    //     } else {
    //       d.filterAmounts[id] = productFilterAmounts[id]
    //     }
    //   })
    // })

    return new HomeLoanProvider(d)
  }

  public static Initialise(d: any): Provider {
    return new HomeLoanProvider(d)
  }

  protected constructor(d: any) {
    super(d)
    this.finspoPanel = d.finspoPanel
    this.hlPackages = d.packages
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkNumber(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "number" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "number", nullable)
  }
}
function checkBoolean(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "boolean" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "boolean", nullable)
  }
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}
